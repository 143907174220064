<template>
  <div class="login-form">
    <form class="row" @submit.prevent="tryLogIn">
      <div class="input-field col s6">
        <input name="login" id="user-login" type="text" v-model="user.login">
        <label for="user-login">Ваш логин</label>
      </div>
      <div class="input-field col s6">
        <input name="pass" id="user-pass" type="password" v-model="user.password">
        <label for="user-login">Ваш пароль</label>
      </div>
      <a class="waves-effect waves-light brown lighten-2 btn col s12" @click.prevent="tryLogIn"><i
          class="material-icons left">cloud</i>Войти</a>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      user: {
        login: '',
        password: '',
      }
    }
  },
  computed: {
    ...mapGetters('User', ['isLoggedIn',]),
  },
  methods: {
    ...mapActions('User', ['userLogIn', 'sessionRefresh']),
    async tryLogIn() {
      await this.userLogIn(this.user);
      // await this.sessionRefresh();
      if (this.isLoggedIn)
        await this.$router.push({ name: 'orderslist' });
      else
        alert("Неверные логин или пароль"); // TODO: Убрать эту заглушку и написать нормальный алерт в репозитории
    },
  },
  mounted() {
    let self = this;
    setTimeout(async () => {
      console.log('sess refresh from login mount');
      await self.sessionRefresh();
      console.log('isLoggedIn:' + self.isLoggedIn);
      if (self.isLoggedIn)
        ('redirect' in self.$route.query) ?
          await self.$router.push({ path: self.$route.query.redirect }) : await self.$router.push({ name: 'orderslist' });
    }, 0)
    //    console.log(process.env);
  },
}
</script>

<style scoped></style>